<template>
	<section id="owner-profile">
		<bg-grid>
			<bg-grid-item :col="12">
				<bg-card>
					<div class="flex justify-space-between align-center mb-16">
						<bg-text size="heading-5"> Profil Pemilik </bg-text>
						<router-link v-if="accessEditProfilPemilik" :to="editPageRoute">
							<bg-button> Ubah </bg-button>
						</router-link>
					</div>

					<bg-divider />

					<bg-grid v-if="loading" class="mt-24">
						<bg-grid-item v-for="i in 4" :cols="3" :key="i">
							<bg-skeleton width="60%" height="20px" />
						</bg-grid-item>
					</bg-grid>

					<template v-else>
						<bg-grid class="mt-24">
							<bg-grid-item :col="3">
								<bg-list-item title="Nama"> {{ owner.name }}</bg-list-item>
							</bg-grid-item>

							<bg-grid-item :col="3">
								<bg-list-item title="Nomor HP">
									{{ owner.phone_number }}
								</bg-list-item>
							</bg-grid-item>

							<bg-grid-item :col="3">
								<bg-list-item title="Alamat">
									{{ owner.address }}
								</bg-list-item>
							</bg-grid-item>

							<bg-grid-item :col="3">
								<bg-list-item title="Provinsi">
									{{ owner.province | nullable }}
								</bg-list-item>
							</bg-grid-item>
						</bg-grid>

						<bg-grid>
							<bg-grid-item :col="3" class="mb-0">
								<bg-list-item title="kota/Kabupaten">
									{{ owner.city | nullable }}
								</bg-list-item>
							</bg-grid-item>

							<bg-grid-item :col="3" class="mb-0">
								<bg-list-item title="Kecamatan">
									{{ owner.subdistrict | nullable }}
								</bg-list-item>
							</bg-grid-item>

							<bg-grid-item :col="3" class="mb-0">
								<bg-list-item title="Kelurahan">
									{{ owner.village | nullable }}
								</bg-list-item>
							</bg-grid-item>
						</bg-grid>
					</template>
				</bg-card>
			</bg-grid-item>
		</bg-grid>
	</section>
</template>

<script>
import {
	BgGrid,
	BgGridItem,
	BgCard,
	BgText,
	BgDivider,
	BgListItem,
	BgSkeleton,
	BgButton,
} from 'bangul-vue';

export default {
	name: 'OwnerProfile',

	components: {
		BgText,
		BgGrid,
		BgGridItem,
		BgCard,
		BgDivider,
		BgListItem,
		BgSkeleton,
		BgButton,
	},

	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		owner: {
			type: Object,
			default: () => ({}),
		},
		propertyId: {
			type: [String, Number],
			default: '',
		},
	},

	filters: {
		nullable: value => value || '-',
	},

	computed: {
		accessEditProfilPemilik() {
			return this.$store.getters.xCheckUserPermission('edit-profil-pemilik');
		},

		editPageRoute() {
			return {
				name: 'edit.owner-profile',
				params: {
					propertyId: this.propertyId,
				},
			};
		},
	},
};
</script>
